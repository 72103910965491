import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import AlternativeLayout from '../components/layout/AlternativeLayout';
import SiteMetadata from '../components/site-metadata';
import WorkTogether from '../svg/WorkTogether';

const Career = () => (
  <>
    <SiteMetadata pathname="career" />
    <AlternativeLayout>
      <section className="py-16">
        <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-20 lg:px-8">
          <div className="text-center lg:text-left">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
              Entwickle mit uns!
            </h1>
            <p className="text-xl lg:text-2xl mt-6 font-light">
              Wir sind ein junges Team aus Würzburg, das gemeinsam die Gesundheitsversorung
              revolutionieren will. Gemeinsam mit der Uniklinik Würzburg möchten wir die Ausbildung
              in der Medizin verbessern und die Arbeit in Krankenhaus und Arztpraxis besser
              strukturieren. Hast du Interesse, an einem Zukunftsthema zu arbeiten und ein großes
              Projekt auf die Beine zu stellen? Kannst du etwas, was uns voranbringt, oder hast du
              Interesse etwas zu lernen und dich einzubringen? Dann melde dich!
            </p>
          </div>
          <div className="flex items-center justify-center px-16 lg:px-8">
            <WorkTogether />
          </div>
        </div>
      </section>
      <section className="py-16">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold">Wen suchen wir?</h2>
          <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
            <div className="flex-1 px-3">
              <Card className="h-full mb-8">
                <p className="font-semibold text-xl">Design</p>
                <p className="mt-4">
                  Farben und Formen sind deine Leidenschaft! Du möchtest etwas gestalten, was gerne
                  angeschaut und benutzt wird. Optimalerweise hast du schon Erfahrung im Webdesign
                  oder freust dich auf neue Herausforderungen!
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="h-full mb-8">
                <p className="font-semibold text-xl">Entwicklung</p>
                <p className="mt-4">
                  Du möchtest etwas entwickeln und damit eine positive Veränderung anstoßen? Du hast
                  Erfahrung mit Javascript, ReactJS, GraphQL, Node.js oder Lust etwas neues zu
                  lernen?
                </p>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="h-full mb-8">
                <p className="font-semibold text-xl">Visionär</p>
                <p className="mt-4">
                  Du hast Träume, Ideen und Visionen. Mit uns möchtest du richtig etwas verändern
                  und die Vorstellungen umsetzen. Was du bisher gemacht hast, ist uns egal, solange
                  du gut darin bist, deine Ideen umzusetzen!
                </p>
              </Card>
            </div>
          </div>
        </div>
      </section>
      <section className="container mx-auto pt-8 pb-16">
        <div className="p-4 bg-gray-200 rounded-lg text-center">
          <h3 className="text-5xl font-semibold">Willst du mehr wissen?</h3>
          <p className="mt-8 text-xl font-light">
            Melde dich gerne bei uns, wenn du unser Projekt interessant findest.
            <br />
            Wir sind immer auf der Suche nach motivierten Leuten, die unser Team vestärken, auf
            welche Art auch immer!
          </p>
          <p className="mt-8">
            <a href="mailto:karriere@morrowmed.de">
              <Button size="xl">Schreib uns!</Button>
            </a>
          </p>
        </div>
      </section>
    </AlternativeLayout>
  </>
);

export default Career;
